/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

import { GTMPageViewPayload } from '@/data/types/GTM';

export const getGTMPageViewEventData = async (payload: GTMPageViewPayload) => {
	const {advantage_member, promoCode, pageTitle, pagePath} = payload;
	return {
		advantage_member,
        promoCode,
        pageTitle,
        pagePath,
	};
};
