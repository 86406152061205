/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

import { sendGTMPageViewEvent } from '@/data/events/handlers/gtm/PageView';
import { GTMPageViewPayload } from '@/data/types/GTM';

type PageViewDelegatorProps = {
	gtm?: GTMPageViewPayload;
};

export const pageViewDelegator = async (payload: PageViewDelegatorProps) => {
	const { gtm } = payload;
	if (gtm) {
		await sendGTMPageViewEvent(gtm);
	}
};
