/*
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023.
 */

import { GA4_EVENT_PAGEVIEW, PAGEVIEW, PAGE_DATA_LAYER } from '@/data/constants/gtm';
import { getGTMPageViewEventData } from '@/data/events/data/gtm/PageView';
import { getGTMConfig } from '@/data/events/handlers/gtm';
import { GTMPageViewPayload } from '@/data/types/GTM';
import TagManager from 'react-gtm-module';

/**
 * Measure transaction details into the Data Layer using the purchase action.
 * @param data transformed input payload from purchase event (see data-type)
 */
export const measure_GA4 = async (data: Awaited<ReturnType<typeof getGTMPageViewEventData>>) => {
	const dataLayerName = PAGE_DATA_LAYER;

	const {
		promoCode,
		advantage_member,
        pageTitle,
        pagePath,
	} = data;

	const tagManagerArgsGA4 = {
		dataLayer: {
			event: GA4_EVENT_PAGEVIEW,
			eventModel: {
                pageTitle,
                pagePath,
				promoCode,
				advantage_member,
			},
		},
		dataLayerName,
	};
	TagManager.dataLayer(tagManagerArgsGA4);
};

/**
 * Measure transaction details into the Data Layer using the purchase action.
 * @param data transformed input payload from purchase event (see data-type)
 */
export const measure_UA = async (data: Awaited<ReturnType<typeof getGTMPageViewEventData>>) => {
	const dataLayerName = PAGE_DATA_LAYER;
	const {
		promoCode,
		advantage_member,
        pageTitle,
        pagePath,
	} = data;

	const tagManagerArgs = {
		dataLayer: {
			event: PAGEVIEW,
            pageTitle,
            pagePath,
			promoCode,
		    advantage_member,
		},
		dataLayerName,
	};
	TagManager.dataLayer(tagManagerArgs);
};

export const sendGTMPageViewEvent = async (payload: GTMPageViewPayload) => {
	const { settings } = payload;
	const { ga4, ua } = getGTMConfig(settings);

	if (ua || ga4) {
		const data = await getGTMPageViewEventData(payload);

		if (ua) {
			try {
				await measure_UA(data);
			} catch (error) {
				console.log(error);
			}
		}

		if (ga4) {
			try {
				await measure_GA4(data);
			} catch (error) {
				console.log(error);
			}
		}
	}
};
